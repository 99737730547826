import { observer } from "mobx-react";
import { useStore } from "./RootStore";
import Tresor from "./components/Tresor/Tresor";
import { Col, ConfigProvider, Row, Spin } from "antd";
import CodeInput from "./components/CodeInput/CodeInput";
import { SWEEP_STAKE_STATES } from "./constants/SweepStakeStates";
import AddressInput from "./components/AddressInput/AddressInput";
import Result from "./components/Result/Result";
import { ConditionsOfParticipationModal } from "./components/ConditionsOfParticipationModal/ConditionsOfParticipationModal";

const App = observer(() => {
	const { sweepStakeStore } = useStore();
	return (
		<ConfigProvider
			theme={{
				token: {
					fontFamily: "brandfont",
					colorPrimary: sweepStakeStore.stylingConfig.style.primaryColor,
					fontSize: "22px",
				},
			}}
		>
			{sweepStakeStore.conditionsOfParticipationMode ? (
				<div
					style={{ backgroundColor: "white", color: "black", padding: "1vh 5vw" }}
					dangerouslySetInnerHTML={{ __html: sweepStakeStore.conditionsOfParticipation }}
				></div>
			) : (
				<div
					className="App"
					style={{
						padding: "0vh 5vw",
						height: "100vh",
						justifyContent: "space-between",
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Row>
						<Col span={24}>
							<h1 style={{ fontSize: "7.5vh", marginBottom: "0px" }}>
								{sweepStakeStore.sweepStakeName ? sweepStakeStore.sweepStakeName : <Spin />}
							</h1>

							<p style={{ marginTop: "0px" }}>
								{sweepStakeStore.stylingConfig.style.logo ? (
									<>
										powered by{" "}
										<img
											src={sweepStakeStore.stylingConfig.style.logo}
											alt="logo"
											style={{ height: "22px", verticalAlign: "text-top" }}
										></img>
									</>
								) : (
									<> </>
								)}
							</p>
						</Col>
					</Row>
					<Row gutter={16} justify={"space-around"} style={{ margin: "0vh 1vw" }} align={"top"}>
						<Col span={24} lg={0}>
							<p style={{ fontSize: "5vh" }}>Bildschirm nicht breit genug</p>
						</Col>
						<Col span={0} lg={11} xl={9}>
							<Tresor />
						</Col>
						<Col span={0} lg={11} xl={9}>
							<div id="parent">
								<div id="container">
									{sweepStakeStore.sweepStakeState === SWEEP_STAKE_STATES.CodeInput ? (
										<CodeInput />
									) : sweepStakeStore.sweepStakeState === SWEEP_STAKE_STATES.AddressInput ? (
										<AddressInput />
									) : sweepStakeStore.sweepStakeState === SWEEP_STAKE_STATES.CheckCode ? (
										<h1 style={{ fontSize: "6vh" }}>
											{sweepStakeStore.stylingConfig.text.checkingCode}
										</h1>
									) : (
										<Result />
									)}
								</div>
							</div>
						</Col>
					</Row>
					<Row>
						<Col span={24} style={{ textAlign: "right" }}>
							<p style={{ marginBottom: "1px" }}>
								{" "}
								{sweepStakeStore.conditionsOfParticipation ? (
									<u
										onClick={() => {
											sweepStakeStore.openConditionsOfParticipation = true;
										}}
									>
										{sweepStakeStore.stylingConfig.text.conditionsOfParticipationLable}
									</u>
								) : (
									""
								)}
							</p>
						</Col>
						<ConditionsOfParticipationModal />
					</Row>
				</div>
			)}
		</ConfigProvider>
	);
});

export default App;
