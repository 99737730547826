// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
	color: white;
	margin: 0px;
	height: 100vh;
	width: 100vw;
}

body::-webkit-scrollbar {
	display: none;
}

button {
	border-radius: 2cqw;
	border: 6px solid rgba(255, 255, 255, 1);
	box-shadow: 8px 8px 20px rgba(0, 0, 0, 0.101945);
	opacity: 1;
	background-color: transparent;
	color: white;
	width: 100%;
}

button:active {
	background-color: #c9c9c9;
	border: 6px solid #c9c9c9;
}

a {
	color: white;
}
#parent {
	container-type: inline-size;
	container-name: parent;
}

#container {
	display: flex;
	flex-direction: column;
	height: calc(1.238 * 100cqw);
	width: 100%;
	padding: 0px 20px;
	justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;CACX,aAAa;CACb,YAAY;AACb;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,mBAAmB;CACnB,wCAAwC;CACxC,gDAAgD;CAChD,UAAU;CACV,6BAA6B;CAC7B,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,yBAAyB;CACzB,yBAAyB;AAC1B;;AAEA;CACC,YAAY;AACb;AACA;CACC,2BAA2B;CAC3B,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,sBAAsB;CACtB,4BAA4B;CAC5B,WAAW;CACX,iBAAiB;CACjB,8BAA8B;AAC/B","sourcesContent":["body {\n\tcolor: white;\n\tmargin: 0px;\n\theight: 100vh;\n\twidth: 100vw;\n}\n\nbody::-webkit-scrollbar {\n\tdisplay: none;\n}\n\nbutton {\n\tborder-radius: 2cqw;\n\tborder: 6px solid rgba(255, 255, 255, 1);\n\tbox-shadow: 8px 8px 20px rgba(0, 0, 0, 0.101945);\n\topacity: 1;\n\tbackground-color: transparent;\n\tcolor: white;\n\twidth: 100%;\n}\n\nbutton:active {\n\tbackground-color: #c9c9c9;\n\tborder: 6px solid #c9c9c9;\n}\n\na {\n\tcolor: white;\n}\n#parent {\n\tcontainer-type: inline-size;\n\tcontainer-name: parent;\n}\n\n#container {\n\tdisplay: flex;\n\tflex-direction: column;\n\theight: calc(1.238 * 100cqw);\n\twidth: 100%;\n\tpadding: 0px 20px;\n\tjustify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
