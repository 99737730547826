import { observer } from "mobx-react";

const BigButton = observer(({ onClick, text }) => {
	return (
		<div>
			<button block style={{ padding: "2vw" }} onClick={() => onClick()}>
				<span style={{ fontSize: "3vw" }}>{text}</span>
			</button>
		</div>
	);
});

export default BigButton;
