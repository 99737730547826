import { Form, Input, message } from "antd";
import { observer } from "mobx-react";
import { useStore } from "../../RootStore";
import BigButton from "../BigButton";

const AddressInput = observer(() => {
	const { sweepStakeStore } = useStore();
	const [form] = Form.useForm();
	const inputStyle = {
		backgroundColor: `rgba(255, 255, 255, 0.5)`,
		borderWidth: "5px",
		borderColor: sweepStakeStore.stylingConfig.style.fontColor,
		color: sweepStakeStore.stylingConfig.style.fontColor,
		padding: "5px",
	};
	return (
		<>
			<p>{sweepStakeStore.stylingConfig.text.addressLable}</p>
			<Form
				autoComplete="off"
				form={form}
				onFinish={(values) => sweepStakeStore.saveAddress(values)}
				onFinishFailed={(e) => message.error(sweepStakeStore.stylingConfig.text.addressError)}
			>
				<Form.Item
					name="firstName"
					rules={[{ required: true, message: "" }]}
					style={{ display: "inline-block", width: "calc(50% - 8px)" }}
				>
					<Input style={inputStyle} placeholder={"Vorname"} size="large" />
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: "" }]}
					name="lastName"
					style={{ display: "inline-block", width: "calc(50% - 8px)", marginLeft: "16px" }}
				>
					<Input style={inputStyle} placeholder={"Nachname"} size="large" />
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: "" }]}
					name="street"
					style={{ display: "inline-block", width: "calc(80% - 8px)" }}
				>
					<Input style={inputStyle} placeholder={"Straße"} size="large" />
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: "" }]}
					name="houseNumber"
					style={{ display: "inline-block", width: "calc(20% - 8px)", marginLeft: "16px" }}
				>
					<Input style={inputStyle} placeholder={"Nr."} size="large" />
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: "" }]}
					name="zip"
					style={{ display: "inline-block", width: "calc(30% - 8px)" }}
				>
					<Input style={inputStyle} placeholder={"PLZ"} size="large" />
				</Form.Item>
				<Form.Item
					rules={[{ required: true, message: "" }]}
					name="city"
					style={{ display: "inline-block", width: "calc(70% - 8px)", marginLeft: "16px" }}
				>
					<Input style={inputStyle} placeholder={"Ort"} size="large" />
				</Form.Item>
			</Form>
			<BigButton text={sweepStakeStore.stylingConfig.text.addressSaveButton} onClick={() => form.submit()} />
		</>
	);
});

export default AddressInput;
