import { makeAutoObservable } from "mobx";
import { message } from "antd";
import { SWEEP_STAKE_STATES } from "../constants/SweepStakeStates";
import { POSSIBLE_CODE_INPUTS } from "../constants/PossibleCodeInputs";

export default class SweepStakeStore {
	sweepStakeState = SWEEP_STAKE_STATES.CodeInput;
	sweepStakeResult = null;
	sweepStakeName = "";
	conditionsOfParticipation = null;
	openConditionsOfParticipation = false;
	code = "";
	errorMessage = "";

	stylingConfig = require("../stylings/noBrandStyling.json");

	sweepstakeserviceHost = "/webapp";

	constructor() {
		makeAutoObservable(this);
		this.loadStyling();
		if (this.conditionsOfParticipationMode) {
			this.loadconditionsOfParticipation();
		} else {
			this.startSession();
		}
	}

	get conditionsOfParticipationMode() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get("conditionsOfParticipation") !== null;
	}

	get id() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get("id");
	}

	get webappkey() {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		return urlParams.get("key");
	}

	loadconditionsOfParticipation() {
		var xhttp = new XMLHttpRequest();
		// xhttp.setRequestHeader("sweepstakeservice_api_key", "ef4185c8-66f7-421f-bbca-b60883ea6d1a");
		let store = this;
		xhttp.onreadystatechange = () => {
			store.sessionOnReadyStateChange(xhttp);
		};
		xhttp.open(
			"GET",
			`${this.sweepstakeserviceHost}/${
				this.webappkey == null ? "noKey" : this.webappkey
			}/conditionsOfParticipation/${this.id == null ? "noId" : this.id}`,
			true
		);
		xhttp.send();
	}

	startSession() {
		if (this.webappkey == null) {
			this.sweepStakeState = SWEEP_STAKE_STATES.FatalError;
			this.errorMessage = this.stylingConfig.text.errorMessage.noKey;
		} else if (this.webappkey && !localStorage.getItem(this.webappkey)) {
			var xhttp = new XMLHttpRequest();
			// xhttp.setRequestHeader("sweepstakeservice_api_key", "ef4185c8-66f7-421f-bbca-b60883ea6d1a");
			let store = this;
			xhttp.onreadystatechange = () => {
				store.sessionOnReadyStateChange(xhttp);
			};
			xhttp.open("POST", `${this.sweepstakeserviceHost}/${this.webappkey}/start`, true);
			xhttp.send();
		} else {
			this.keepSessionAlive();
		}
		setInterval(() => {
			this.keepSessionAlive();
		}, 1 * 60 * 1000);
	}

	keepSessionAlive() {
		console.log("keep Session alive");
		if (this.webappkey && localStorage.getItem(this.webappkey)) {
			var xhttp = new XMLHttpRequest();
			let store = this;
			xhttp.onreadystatechange = () => {
				store.sessionOnReadyStateChange(xhttp);
			};
			// xhttp.setRequestHeader("sweepstakeservice_api_key", "ef4185c8-66f7-421f-bbca-b60883ea6d1a");
			xhttp.open(
				"POST",
				`${this.sweepstakeserviceHost}/${this.webappkey}/keepalive/${localStorage.getItem(
					this.webappkey
				)}`,
				true
			);
			xhttp.send();
		} else {
			this.startSession();
		}
	}

	sessionOnReadyStateChange(xhttp) {
		if (xhttp.readyState === 4 && xhttp.status === 200) {
			let serverResponse = JSON.parse(xhttp.responseText);
			this.sweepStakeName = serverResponse.name;
			this.conditionsOfParticipation = serverResponse.conditionsOfParticipation;
			this.showAdressInput = serverResponse.showAdressInput;
			localStorage.setItem(this.webappkey, serverResponse.sessionId);
		} else if (xhttp.readyState === 4) {
			let serverResponse = xhttp.statusText === "Gateway Timeout" ? {"status": 0} :JSON.parse(xhttp.responseText);
			this.sweepStakeState = SWEEP_STAKE_STATES.FatalError;

			switch (serverResponse.status) {
				case 404:
					this.errorMessage = this.stylingConfig.text.errorMessage.sweepstakeNotFound;
					break;
				case 412:
					this.errorMessage = this.stylingConfig.text.errorMessage.sweepstakeNotStarted;
					break;
				case 429:
					this.errorMessage = this.stylingConfig.text.errorMessage.toManyWebappsRunning;
					break;
				default:
					this.errorMessage = this.stylingConfig.text.errorMessage.defaultErrorMessage;
			}
		}
	}

	async setStyling(styling) {
		if (!styling) {
			styling = require("../stylings/noBrandStyling.json");
		}
		console.log("set Styling");
		console.log(styling);
		this.stylingConfig = styling;
		const myFont = new FontFace("brandfont", `url(${this.stylingConfig.style.font})`);
		await myFont.load();
		document.fonts.add(myFont);

		document.body.style.fontFamily = "brandfont";
		document.body.style.color = this.stylingConfig.style.fontColor;
		document.body.style.backgroundImage = this.stylingConfig.style.background;
	}

	async loadStyling() {
		if (this.webappkey == null) {
			this.setStyling(null);
		} else {
			var xhttp = new XMLHttpRequest();
			xhttp.onreadystatechange = async () => {
				if (xhttp.readyState === 4 && xhttp.status === 200) {
					let serverResponse = JSON.parse(xhttp.responseText);
					this.setStyling(serverResponse);
				} else {
					this.setStyling(null);
				}
			};
			xhttp.open("GET", `${this.sweepstakeserviceHost}/${this.webappkey}/styling`, true);
			xhttp.send();
		}
	}

	redeemCode() {
		this.sweepStakeState = SWEEP_STAKE_STATES.CheckCode;
		setTimeout(() => {
			var xhttp = new XMLHttpRequest();
			var store = this;
			xhttp.onreadystatechange = function () {
				if (xhttp.readyState === 4 && xhttp.status === 200) {
					var serverResponse = JSON.parse(xhttp.responseText);
					store.sweepStakeResult = serverResponse;
					if (store.sweepStakeResult.id === null) {
						store.sweepStakeState = SWEEP_STAKE_STATES.ResultNoWin;
					} else {
						store.sweepStakeState = SWEEP_STAKE_STATES.ResultWin;
					}
				} else if (xhttp.readyState === 4) {
					let serverResponse = JSON.parse(xhttp.responseText);
					store.sweepStakeState = SWEEP_STAKE_STATES.ResultError;
					switch (serverResponse.status) {
						case 404:
							store.errorMessage = store.stylingConfig.text.errorMessage.codeNotFound;
							break;
						case 208:
							store.errorMessage = store.stylingConfig.text.errorMessage.codeAlreadyResolved;
							break;
						case 412:
							store.errorMessage = store.stylingConfig.text.errorMessage.sweepstakeGone;
							break;
						default:
							store.errorMessage = store.stylingConfig.text.errorMessage.defaultErrorMessage;
					}
				}
			};
			xhttp.open(
				"POST",
				`${this.sweepstakeserviceHost}/${this.webappkey}/${localStorage.getItem(this.webappkey)}/redeem/${
					this.code
				}`,
				true
			);
			xhttp.send();
		}, 3000);
	}

	inputOneLetterInCode(letter) {
		if (
			POSSIBLE_CODE_INPUTS.includes(letter) &&
			this.sweepStakeState === SWEEP_STAKE_STATES.CodeInput &&
			this.code.length < 9
		) {
			this.code += letter;
			if (this.code.length === 9) {
				//this.sweepStakeState = SWEEP_STAKE_STATES.AddressInput;
				this.redeemCode();
			}
		} else {
			console.error(
				`${letter} can not be inputed. current Code "${this.code}" current SweepStakeState "${this.sweepStakeState}"`
			);
		}
	}

	goToAddressInput() {
		this.sweepStakeState = SWEEP_STAKE_STATES.AddressInput;
	}

	saveAddress(address) {
		console.log(address);
		var xhttp = new XMLHttpRequest();
		var store = this;
		xhttp.onreadystatechange = function () {
			if (xhttp.readyState === 4 && xhttp.status === 200) {
				var serverResponse = JSON.parse(xhttp.responseText);
				console.log({ serverResponse });
				message.success(store.stylingConfig.text.addressSuccess);
				store.resetCode();
			} else if (xhttp.readyState === 4) {
				let serverResponse = JSON.parse(xhttp.responseText);
				store.sweepStakeState = SWEEP_STAKE_STATES.ResultError;
				switch (serverResponse.status) {
					case 404:
						store.errorMessage = store.stylingConfig.text.errorMessage.codeNotFound;
						break;
					case 412:
						store.errorMessage = store.stylingConfig.text.errorMessage.sweepstakeGone;
						break;
					default:
						store.errorMessage = store.stylingConfig.text.errorMessage.defaultErrorMessage;
				}
			}
		};
		xhttp.open("POST", `${this.sweepstakeserviceHost}/${this.webappkey}/saveWinnerData/${this.code}`, true);
		xhttp.setRequestHeader("Content-Type", "application/json; charset=UTF-8");
		xhttp.send(JSON.stringify(address));
	}


	resetCode() {
		this.code = "";
		this.sweepStakeResult = null;
		this.sweepStakeState = SWEEP_STAKE_STATES.CodeInput;
	}
}
