import { Row } from "antd";
import { observer } from "mobx-react";
import { useStore } from "../../RootStore";
import "./CodeInput.css";
import { POSSIBLE_CODE_INPUTS } from "../../constants/PossibleCodeInputs";
import BigButton from "../BigButton";

const CodeInput = observer(() => {
	const { sweepStakeStore } = useStore();
	return (
		<>
			<p>{sweepStakeStore.stylingConfig.text.codeInputLable}</p>
			<Row id="codeInputFields" justify={"space-between"}>
				{POSSIBLE_CODE_INPUTS.map((option) => (
					<button class="codeInputField" onClick={() => sweepStakeStore.inputOneLetterInCode(option)}>
						<span style={{ fontSize: "10cqw" }}>{option}</span>
					</button>
				))}
			</Row>

			<BigButton
				onClick={() => sweepStakeStore.resetCode()}
				text={sweepStakeStore.stylingConfig.text.resetCodeButton}
			/>
		</>
	);
});

export default CodeInput;
