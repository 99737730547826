import { observer } from "mobx-react";
import "./Tresor.css";
import { useStore } from "../../RootStore";

const Tresor = observer(() => {
	const { sweepStakeStore } = useStore();
	return (
		<div id="parent">
			<img class="back" src={require("../../assests/tresor.png")} alt="" />
			<div id="inside">
				{sweepStakeStore.sweepStakeResult === null || sweepStakeStore.sweepStakeResult.id === null ? (
					<>
						<h3 id="priceHeadline"> </h3>
						<div id="priceImg">
							<img height={"100%"} src="" alt="" />
						</div>
					</>
				) : (
					<>
						<h3 id="priceHeadline">{sweepStakeStore.sweepStakeResult.name}</h3>
						<div id="priceImg">
							<img
								style={{ maxWidth: "90%", maxHeight: "90%" }}
								src={`${sweepStakeStore.sweepStakeResult.img}`}
								alt=""
							/>
						</div>
					</>
				)}
			</div>
			<div id="wholeDoor" class={sweepStakeStore.sweepStakeState}>
				<img
					id="tuer"
					class={sweepStakeStore.sweepStakeState}
					src={require("../../assests/tuer_2.png")}
					alt=""
				/>
				<div id="lable" class={sweepStakeStore.sweepStakeState}>
					{sweepStakeStore.stylingConfig.text.tresorLable}
				</div>
				<img
					id="dreher"
					class={sweepStakeStore.sweepStakeState}
					src={require("../../assests/dreher.png")}
					alt=""
				/>

				<div id="numberFields">
					<div class={`numberfield ${sweepStakeStore.sweepStakeState}`}>
						<span>{sweepStakeStore.code.slice(0, 3)}</span>
					</div>
					<div class={`numberfield ${sweepStakeStore.sweepStakeState}`}>
						<span>{sweepStakeStore.code.slice(3, 6)}</span>
					</div>
					<div class={`numberfield ${sweepStakeStore.sweepStakeState}`}>
						<span>{sweepStakeStore.code.slice(6, 9)}</span>
					</div>
				</div>
			</div>
		</div>
	);
});

export default Tresor;
