import { Button, Modal } from "antd";
import { observer } from "mobx-react";
import { useStore } from "../../RootStore";

export const ConditionsOfParticipationModal = observer(() => {
	const { sweepStakeStore } = useStore();
	return (
		<Modal
			open={sweepStakeStore.openConditionsOfParticipation}
			width="75%"
			title={sweepStakeStore.stylingConfig.text.conditionsOfParticipationLable}
			closable={false}
			footer={
				<Button type="primary" block onClick={() => (sweepStakeStore.openConditionsOfParticipation = false)}>
					Schließen
				</Button>
			}
			onCancel={() => (sweepStakeStore.openConditionsOfParticipation = false)}
		>
			<div dangerouslySetInnerHTML={{ __html: sweepStakeStore.conditionsOfParticipation }}></div>
		</Modal>
	);
});
