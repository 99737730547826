import { observer } from "mobx-react";
import { useStore } from "../../RootStore";
import BigButton from "../BigButton";
import { SWEEP_STAKE_STATES } from "../../constants/SweepStakeStates";

const Result = observer(() => {
	const { sweepStakeStore } = useStore();
	console.log(sweepStakeStore.sweepStakeState);
	return (
		<>
			<h1 style={{ fontSize: "6vh", marginTop: "0px" }}>
				{sweepStakeStore.stylingConfig.text.resultText[sweepStakeStore.sweepStakeState].title}
			</h1>
			<p style={{ margin: "16px 0", fontSize: "4vh" }}>
				{sweepStakeStore.stylingConfig.text.resultText[sweepStakeStore.sweepStakeState].describtion}
				{sweepStakeStore.sweepStakeState === SWEEP_STAKE_STATES.ResultError ||
				sweepStakeStore.sweepStakeState === SWEEP_STAKE_STATES.FatalError
					? sweepStakeStore.errorMessage
					: ""}
			</p>
			<p style={{ margin: "16px 0", fontSize: "4vh" }}>
				{sweepStakeStore.stylingConfig.text.resultText[sweepStakeStore.sweepStakeState].emoji}
			</p>
			{sweepStakeStore.sweepStakeState !== SWEEP_STAKE_STATES.FatalError ? (
				sweepStakeStore.showAdressInput && sweepStakeStore.sweepStakeState === SWEEP_STAKE_STATES.ResultWin  && !sweepStakeStore.sweepStakeResult.local ? (
					<BigButton
						text={sweepStakeStore.stylingConfig.text.addressButton}
						onClick={() => sweepStakeStore.goToAddressInput()}
					/>
				) : (
					<BigButton
						text={sweepStakeStore.stylingConfig.text.newCodeButton}
						onClick={() => sweepStakeStore.resetCode()}
					/>
				)
			) : (
				<></>
			)}
		</>
	);
});

export default Result;
