// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#codeInputFields {
	container-type: inline-size;
	container-name: parent;
}

.codeInputField {
	height: 17cqw;
	width: 17cqw;
	margin: 1.5cqw;
	background-color: white;
	color: black;
	font-family: brandfont;
}
`, "",{"version":3,"sources":["webpack://./src/components/CodeInput/CodeInput.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,sBAAsB;AACvB;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,cAAc;CACd,uBAAuB;CACvB,YAAY;CACZ,sBAAsB;AACvB","sourcesContent":["#codeInputFields {\n\tcontainer-type: inline-size;\n\tcontainer-name: parent;\n}\n\n.codeInputField {\n\theight: 17cqw;\n\twidth: 17cqw;\n\tmargin: 1.5cqw;\n\tbackground-color: white;\n\tcolor: black;\n\tfont-family: brandfont;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
