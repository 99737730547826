import { createContext, useContext } from "react";
import SweepStakeStore from "./stores/SweepStakeStore";

export const rootStore = {
	sweepStakeStore: new SweepStakeStore(),
};

export const StoreContext = createContext(rootStore);

export function useStore() {
	return useContext(StoreContext);
}
